export default {
  gooey_key: "sk-5qXKM3DhsPVMlk4n14qzjazHLmyyLILuUUzFsOnlX45TGHtc",
  server_url: "https://mh-main.fly.dev",
  uploadsFolder: "/volume/uploads/",
  cacheFolder: "/volume/cache",
};

// export default {
//   gooey_key: "sk-5qXKM3DhsPVMlk4n14qzjazHLmyyLILuUUzFsOnlX45TGHtc",
//   server_url: "http://localhost:3000",
//   uploadsFolder: "./uploads/",
//   cacheFolder: "./cache",
// };
