import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Image,
  TextInput,
  TextArea,
} from "grommet";
import { io } from "socket.io-client";
import config from "./config";

let socket;

function LivePolling({ state }) {
  const [photos, setPhotos] = useState(undefined);
  const [enablePoll, setEnablePoll] = useState(false);
  const [vote, setVote] = useState(undefined);
  useEffect(() => {
    if (state) {
      setPhotos(state.photos);
      setEnablePoll(state.enablePoll);
    }
  }, [state]);

  function recordVote(vote) {
    socket.emit("poll-images:vote", { vote });
    setVote(vote);
  }

  return (
    <Box>
      <Text>Live Polling</Text>
      {vote || vote === 0 || vote === 1 ? (
        <Text>Thank you for Voting</Text>
      ) : (
        <Text>Click on image to vote</Text>
      )}
      {photos ? (
        <Box gap="medium">
          <Button onClick={() => recordVote(0)}>
            <Box width="small" height="small">
              <Image src={photos[0]} />
            </Box>
          </Button>
          <Button onClick={() => recordVote(1)}>
            <Box width="small" height="small">
              <Image src={photos[1]} />
            </Box>
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}

function ImageManipulation({ state }) {
  const [canComment, setCanComment] = useState(false);
  const [comment, setComment] = useState("");
  useEffect(() => {
    if (state) {
      setCanComment(state.enableComments);
    }
  }, [state]);

  function sendComment() {
    setComment("");
    socket.emit("manipulate-image:add-comment", { comment });
  }

  return (
    <Box>
      {canComment ? (
        <Box gap={"small"}>
          <Text>Send your comment to the photo on the screen</Text>
          <TextArea
            placeholder={"Comment"}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Button primary label={"Send"} onClick={sendComment} />
        </Box>
      ) : null}
    </Box>
  );
}

export function Participate() {
  const [screen, setScreen] = useState(-1);
  const [livePolling, setLivePolling] = useState(undefined);
  const [imageManipulationState, setImageManipulationState] =
    useState(undefined);

  useEffect(() => {
    socket = io(config.server_url);
    socket.on("screen:change", (msg) => {
      console.log(msg);
      setScreen(msg.screen);
    });
    socket.on("app:state", (msg) => {
      setScreen(msg.screen);
    });

    socket.on("manipulate-image:add", (msg) => {
      console.log("manipulate image received", msg);
      setImageManipulationState(msg);
      //   setPhotos(msg.imageUrls);
    });

    socket.on("manipulate-image:enable-comments", (msg) => {
      console.log("manipulate image enable comments received", msg);
      setImageManipulationState(msg);
      //   setImageManipulation(msg);
    });

    socket.on("manipulate-image:add-comment", (msg) => {
      console.log("manipulate image add comment received", msg);
      setImageManipulationState(msg);
      //   setImageManipulation(msg);
    });

    socket.on("poll-images:enable", (msg) => {
      console.log("poll image enable received", msg);
      setLivePolling(msg);
    });

    socket.on("poll-images:add", (msg) => {
      console.log("poll image add received", msg);
      setLivePolling(msg);
    });

    socket.on("poll-images:reset", (msg) => {
      console.log("poll image reset received", msg);
      setLivePolling(msg);
    });
  }, []);

  return (
    <Box>
      <Heading level={3}>Participate</Heading>
      <Text size={"small"}>Wait for instructions from the performers</Text>
      <Box height="2em"></Box>
      {screen === 2 ? (
        <ImageManipulation state={imageManipulationState} />
      ) : null}
      {screen === 3 ? <LivePolling state={livePolling} /> : null}
    </Box>
  );
}
