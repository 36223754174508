import {
  Box,
  Text,
  Heading,
  Tabs,
  Tab,
  Data,
  Toolbar,
  DataSearch,
  DataTable,
  Image,
  Button,
  Select,
  TextInput,
  Spinner,
  CheckBox,
  CheckBoxGroup,
  RadioButtonGroup,
} from "grommet";
import { useState, useEffect, useCallback } from "react";
import users from "./data/users.json";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import config from "./config";
import axios from "axios";
import { ImageGrid } from "./components/ImageGrid.js";
const base64 = require("base-64");
var utf8 = require("utf8");

function getCacheName(text) {
  var bytes = utf8.encode(text);
  var encoded = base64.encode(bytes);
  return encoded;
}

let socket;

function Files({ setUsers, setFile }) {
  const [activeFile, setActiveFile] = useState(
    localStorage.getItem("activeFile", -1)
  );
  const [files, setFiles] = useState([]);
  useEffect(() => {
    axios.get(`${config.server_url}/stat`).then(({ data }) => {
      const { files } = data;
      console.log(files);
      setFiles(files);
    });
  }, []);

  useEffect(() => {
    console.log("active file changed", activeFile);
    // const selectedFileName = files.filter((file) => file === activeFile)[0];
    console.log({ files, activeFile });
    if (files.length != 0 && activeFile != -1) {
      axios
        .get(`${config.server_url}/stat/${activeFile}`)
        .then(({ data }) => {
          const { users } = data;
          console.log(users);
          setFile(activeFile);
          setUsers(users);
        })
        .catch((err) => {
          console.log(`could not get stat for ${activeFile}`);
          console.error(err);
        });
    }
  }, [activeFile, files]);

  return (
    <Box>
      <Box border round="xsmall" pad="small">
        <Heading level={3}>Files</Heading>
        {files ? (
          <Box>
            <RadioButtonGroup
              name={"files"}
              options={files}
              value={activeFile}
              onChange={(event) => {
                localStorage.setItem("activeFile", event.target.value);
                setFile(event.target.value);
                setActiveFile(event.target.value);
              }}
            />
            {/* {files.map((file, ix) => (
              <Button plain onClick={() => setActiveFile(file)}>
                <CheckBox
                  key={ix}
                  label={file}
                  checked={file}
                  onChange={(e) => {
                    console.log(e.target.checked, ix);
                  }}
                />
              </Button>
            ))} */}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

function UserTable({ users }) {
  return (
    <Data data={users}>
      <DataTable
        columns={[
          {
            property: "photo",
            render: (datum) => (
              <Box width={"xxsmall"} height={"xxsmall"} overflow={"hidden"}>
                {datum.photo != "" ? (
                  //   <Link to={`/${datum.id}/userdata`}>
                  <Image fit="cover" src={`${datum.photo}`} />
                ) : (
                  //   </Link>
                  <Box fill background="black"></Box>
                )}
              </Box>
            ),
          },
          {
            property: "email",
            primary: true,
            render: (datum) => (
              <Box>
                <Text>{datum.name}</Text>
                <Text size={"xsmall"}>{datum.email}</Text>
              </Box>
            ),
          },
        ]}
      />
    </Data>
  );
}

function ImageManipulation({ users, fileName }) {
  const [photos, setPhotos] = useState([]);
  const [email, setEmail] = useState(undefined);
  const [prompt, setPrompt] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(undefined);
  const [enableComments, setEnableComments] = useState(false);
  const [comment, setComment] = useState("");

  async function onSelect(ix1, ix2) {
    console.log({ ix1, ix2 });
    socket.emit("manipulate-image:add", {
      url:
        `${config.server_url}/` +
        getCacheName(fileName + users[ix1].email) +
        `prompt_${ix2}`,
      //   url: users[ix1].email,
    });
  }

  async function generateMeme(email, prompt) {
    console.log(email);
    setPreviewImage(undefined);
    setLoading(true);
    const response = await fetch(
      "https://api.gooey.ai/v2/EmailFaceInpainting/",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${config.gooey_key}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email_address: email,
          text_prompt: prompt,
        }),
      }
    );

    const data = await response.json();
    setLoading(false);
    console.log(response.status, data);
    setPreviewImage(data.output.diffusion_images[0]);
  }

  async function generatePreview() {
    if (email && prompt) {
      await generateMeme(email, prompt);
    }
  }

  async function publishImage() {
    socket.emit("manipulate-image:add", { url: previewImage });
  }

  async function resetState() {
    setPrompt("");
    setLoading(false);
    setPreviewImage("");
  }

  async function sendComment() {
    console.log(comment);
    socket.emit("manipulate-image:add-comment", { comment });
  }

  useEffect(() => {
    console.log("comment enable changed", enableComments);
    socket.emit("manipulate-image:enable-comments", { state: enableComments });
  }, [enableComments]);

  return (
    <Box gap="medium" margin={{ top: "large" }}>
      <Box border pad={"small"}>
        <Heading level={5} margin={{ top: "none" }}>
          Dynamic Prompts
        </Heading>
        <Box direction="row-responsive" gap="small">
          <Box width="small" height="small" border>
            {previewImage ? <Image src={previewImage} /> : null}
            {loading ? (
              <Box fill justify="center" align="center">
                <Spinner message="Generating" size="medium" />
              </Box>
            ) : null}
          </Box>
          <Box gap="xsmall">
            <TextInput
              placeholder="Enter new prompt"
              value={prompt}
              onChange={(event) => setPrompt(event.target.value)}
            />
            <Box direction="row-responsive" gap={"small"} align="center">
              <Select
                placeholder="Users"
                options={users}
                valueKey="email"
                onChange={({ option }) => {
                  // console.log(option);
                  setEmail(option.email);
                }}
              />
            </Box>
            <Box direction={"row-responsive"} gap={"small"}>
              <Button primary label={"Preview"} onClick={generatePreview} />
              <Button primary label={"Publish"} onClick={publishImage} />
              <Button primary label={"Reset"} onClick={resetState} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box border pad={"small"}>
        <Heading level={5} margn="none">
          Comments
        </Heading>
        <Box gap={"small"}>
          <CheckBox
            checked={enableComments}
            label="Enable Comments"
            onChange={(e) => setEnableComments(e.target.checked)}
          />
          <Box direction={"row-responsive"} gap="small">
            <TextInput
              placeholder="Your Comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Button label={"Send"} onClick={sendComment}></Button>
          </Box>
        </Box>
      </Box>
      <Box border pad={"small"} gap={"small"}>
        <Heading level={5} margin="none">
          Pre Generated Images
        </Heading>
        <ImageGrid
          fileName={fileName}
          users={users}
          onSelect={onSelect}
          fileKey={"prompt"}
          size={4}
          interactive={true}
        />
      </Box>
    </Box>
  );
}

function LivePolling({ users, fileName }) {
  const [enablePoll, setEnablePoll] = useState(false);
  async function onSelect(ix1, ix2) {
    console.log({ ix1, ix2 });
    socket.emit("poll-images:add", {
      url:
        `${config.server_url}/` +
        getCacheName(fileName + users[ix1].email) +
        `job_prompt_${ix2}`,
      //   url: users[ix1].email,
    });
  }

  function enablePolling() {
    socket.emit("poll-images:enable", { enable: enablePoll });
  }

  function resetPoll() {
    console.log("resetting poll");
    setEnablePoll(false);
    socket.emit("poll-images:reset", {});
  }

  return (
    <Box gap={"medium"} margin={{ top: "medium" }}>
      <Box border pad={"small"} gap={"small"}>
        <CheckBox
          label="Enable Polling"
          value={enablePoll}
          onChange={(e) => {
            setEnablePoll(e.target.checked);
            enablePolling();
          }}
        />
      </Box>
      <ImageGrid
        fileName={fileName}
        users={users}
        onSelect={onSelect}
        fileKey={"job_prompt"}
        size={3}
        interactive={true}
        onClear={resetPoll}
      />
    </Box>
  );
}

export function Manager() {
  const [activeTab, setActiveTab] = useState(-1);
  const [users, setUsers] = useState([]);
  const [fileName, setFileName] = useState(undefined);

  useEffect(() => {
    socket = io(config.server_url);
    socket.on("screen:change", (msg) => {
      console.log(msg);
    });
  }, []);

  useEffect(() => {
    console.log({ users });
    if (activeTab != -1) {
      console.log(`tab changed to ${activeTab}`);
    }
    socket.emit("screen:change", { screen: activeTab });
  }, [activeTab, users]);

  return (
    <Box fill pad="xsmall" gap="small">
      <Box direction={"row-responsive"} gap={"small"} align={"baseline"}>
        <Heading level={5} margin="none">
          Manager
        </Heading>
        <Text size={"xsmall"}>{config.server_url}</Text>
      </Box>
      <Tabs
        alignSelf="start"
        onActive={(e) => {
          setActiveTab(e);
        }}
      >
        <Tab title="All Users">
          <Box gap={"small"}>
            <Files setUsers={setUsers} setFile={setFileName} />
            {/* <UserTable users={users} /> */}
            <Box direction={"row-responsive"} gap="large">
              <Box gap={"small"}>
                <Heading level={4} margin={"none"}>
                  Memes
                </Heading>
                <ImageGrid
                  fileName={fileName}
                  interactive={false}
                  users={users}
                  fileKey={"prompt"}
                  size={4}
                />
              </Box>
              <Box gap={"small"}>
                <Heading level={4} margin={"none"}>
                  Occupations
                </Heading>

                <ImageGrid
                  fileName={fileName}
                  interactive={false}
                  users={users}
                  fileKey={"job_prompt"}
                  size={3}
                />
              </Box>
            </Box>
          </Box>
        </Tab>
        <Tab title="Consent">
          <Box border round="xsmall" pad="small">
            <Heading level={4} margin="none">
              Consent
            </Heading>
          </Box>
        </Tab>
        <Tab title="Image Manipulation">
          <ImageManipulation fileName={fileName} users={users} />
        </Tab>

        <Tab title="Live Polling">
          <LivePolling fileName={fileName} users={users} />
        </Tab>
      </Tabs>
    </Box>
  );
}

//
