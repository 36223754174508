import { useEffect, useState } from "react";
import { Text, Box, Heading, Image, Stack } from "grommet";
import { Like, Send } from "grommet-icons";
import { io } from "socket.io-client";
import config from "./config";

const base64 = require("base-64");
var utf8 = require("utf8");

function getCacheName(text) {
  var bytes = utf8.encode(text);
  var encoded = base64.encode(bytes);
  return encoded;
}

const userNames = [
  "@angry-leopard-23",
  "@hungry-cheetah-32",
  "@people-hater-90",
];

function ImageManipulation({ state }) {
  const [enableComments, setEnableComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    if (state) {
      console.log(`image manipulation state changed`, state);
      setEnableComments(state.enableComments);
      setComments(state.comments);
      setPhotos(state.imageUrls);
    }
  }, [state]);

  return (
    <Box fill>
      {!enableComments ? (
        <Box flex={"grow"} gap={"large"}>
          {photos
            .slice()
            .reverse()
            .map((photo, ix) => (
              <Box width="large" height="large">
                <Image key={ix} fit="cover" src={photo.url} />
              </Box>
            ))}
        </Box>
      ) : null}
      {enableComments ? (
        <Box gap={"medium"} direction="row-responsive">
          <Box width="large" height="large">
            {photos && photos.slice().reverse()[0] ? (
              <Image fit="cover" src={photos.reverse()[0].url} />
            ) : null}
          </Box>
          <Box gap={"small"} overflow="scroll" height={"large"}>
            {comments &&
              comments.reverse().map((comment) => {
                return (
                  <Box border round="xsmall" width="fit-content" pad={"medium"}>
                    <Box gap="xsmall">
                      <Text weight={900}>
                        {
                          userNames[
                            Math.floor(Math.random() * userNames.length)
                          ]
                        }
                      </Text>
                      <Text>{comment}</Text>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}

function LivePollingPhotos({ state }) {
  const [photos, setPhotos] = useState(undefined);
  const [votes, setVotes] = useState([0, 0]);
  useEffect(() => {
    if (state) {
      console.log(`live polling state changed`, state);
      setPhotos(state.photos);
      setVotes(state.votes);
    }
  }, [state]);
  return (
    <Box fill gap="medium">
      <Heading level={3} margin="none">
        Choose One Person
      </Heading>
      <Box direction={"row-responsive"} gap={"small"}>
        {photos ? (
          <Box direction="row-responsive" align="center" gap={"medium"}>
            <Stack anchor={"top-left"}>
              <Box width="medium" height="medium">
                <Image fit="cover" src={photos[0]}></Image>
              </Box>
              <Box background={"accent-1"} pad="small">
                <Heading level={2} margin={"none"}>
                  {votes[0]}
                </Heading>
              </Box>
            </Stack>
            <Text weight={900} size="large">
              vs
            </Text>
            <Stack anchor={"top-left"}>
              <Box width="medium" height="medium">
                <Image fit="cover" src={photos[1]}></Image>
              </Box>
              <Box background={"accent-1"} pad="small">
                <Heading level={2} margin={"none"}>
                  {votes[1]}
                </Heading>
              </Box>
            </Stack>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

export function Show() {
  const [screen, setScreen] = useState(-1);
  const [photos, setPhotos] = useState([]);
  const [livePolling, setLivePolling] = useState(undefined);
  const [imageManipulation, setImageManipulation] = useState(undefined);

  function getTitle() {
    let title = "";
    switch (screen) {
      case -1:
        title = "Welcome";
        break;
      case 0:
        title = "Welcome";
        break;
      case 1:
        title = "Consent";
        break;
      case 2:
        title = "Comment";
        break;
      case 3:
        title = "Poll";
        break;
      default:
        title = "Welcome";
        break;
    }
    return title;
  }

  useEffect(() => {
    const socket = io(config.server_url);
    socket.on("screen:change", (msg) => {
      console.log(msg);
      setScreen(msg.screen);
    });

    socket.on("app:state", (msg) => {
      console.log("app state", msg);
      setScreen(msg.screen);
    });

    socket.on("manipulate-image:add", (msg) => {
      console.log("manipulate image received", msg);
      setImageManipulation(msg);
    });

    socket.on("manipulate-image:enable-comments", (msg) => {
      console.log("manipulate image enable comments received", msg);
      setImageManipulation(msg);
    });

    socket.on("manipulate-image:add-comment", (msg) => {
      console.log("manipulate image add comment received", msg);
      setImageManipulation(msg);
    });

    socket.on("poll-images:add", (msg) => {
      console.log("poll image add received", msg);
      setLivePolling(msg);
    });
    socket.on("poll-images:vote", (msg) => {
      console.log("poll image vote received", msg);
      setLivePolling(msg);
    });
    socket.on("poll-images:reset", (msg) => {
      console.log("poll image reset received", msg);
      setLivePolling(msg);
    });
  }, []);
  return (
    <Box>
      <Box align={"baseline"} gap="small" direction="row-responsive">
        <Heading level={3}>Mining Hate</Heading>
        <Heading level={5}>{getTitle()}</Heading>
      </Box>

      {screen === 1 ? <Heading level={1}>Consent</Heading> : null}
      {screen === 2 ? (
        <ImageManipulation photos={photos} state={imageManipulation} />
      ) : null}
      {screen === 3 ? (
        <LivePollingPhotos photos={photos} state={livePolling} />
      ) : null}
    </Box>
  );
}
