import { Box, RangeInput, Text } from "grommet";
import { useEffect, useState } from "react";
import styled from "styled-components";

const AbsoluteBox = styled(Box)`
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};
  z-index: ${(props) => props.z};
`;

const seedTweets = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet efficitur augue. Morbi eu laoreet augue. Curabitur porta convallis fermentum.",
  "Nulla id tempus nibh. Pellentesque lectus ipsum, condimentum posuere orci quis, pellentesque pharetra diam. Nullam quis nisl sit amet leo fringilla rhoncus.",
  "Nunc sed interdum neque, nec tincidunt felis. Vestibulum et sem est. Nulla pharetra, nibh sagittis dapibus rhoncus, leo justo accumsan veli.",
  "id ullamcorper lorem nisi euismod lectus. Nam sed dictum eros. Nullam vitae lorem pellentesque, imperdiet augue id, sollicitudin est.",
  "Pellentesque eu porttitor velit. Curabitur at diam est. Duis fermentum varius tincidunt. Aenean sagittis, ligula quis maximus tempus",
  "turpis urna imperdiet neque, sit amet vehicula velit quam sit amet neque. Donec scelerisque ultrices nulla, sed consequat ipsum tempus sed.",
  "Praesent maximus lectus justo, nec fermentum tortor dapibus vel. Suspendisse quis ante pellentesque, tincidunt ex eu, auctor tortor. Maecenas",
  "tincidunt suscipit mattis. Suspendisse a varius risus. Nullam gravida vitae nulla venenatis gravida. Curabitur bibendum orci id ultrices dapibus.",
  "Donec vitae ante at odio sagittis dictum quis et nisl. Vivamus erat orci, suscipit semper mauris ut, posuere ultricies diam. Aenean odio libero,",
  "ultricies ut hendrerit vitae, tincidunt a turpis. Vivamus gravida non quam sed congue. Donec dapibus fringilla metus a dapibus.",
  "Praesent imperdiet sollicitudin purus. Aenean felis velit, posuere ac metus id, venenatis viverra est.",
  "In in lobortis libero. Nullam dictum tincidunt mauris, ut accumsan urna rhoncus et.",
  "Curabitur velit nisl, viverra eget auctor sed, aliquet ut elit. Phasellus varius nibh turpis, a pretium sem ornare sed. Quisque justo libero",
  "ultrices nec placerat nec, tempus sed ligula.",
  "Nulla euismod aliquam urna, at tincidunt ipsum bibendum et. Maecenas euismod elit diam, ac pharetra nibh convallis quis. Nullam vulputate est ac dignissim suscipit.",
  "Ut fermentum non quam ut semper. In eget ornare tortor. Quisque libero purus, congue sit amet imperdiet ut, euismod et turpis. Vivamus quis metus nec orci hendrerit ultrices.",
  "Fusce sit amet placerat massa. Pellentesque iaculis vitae sem et ullamcorper.",
  "Proin porta dictum libero, in hendrerit mauris rutrum eleifend. Aliquam tincidunt libero quis purus varius, vel eleifend arcu semper.",
  "Praesent aliquet erat id pellentesque finibus. Maecenas maximus purus a lacus dapibus malesuada. Donec molestie elit ligula, et faucibus diam tincidunt at.",
  "Cras quis convallis ex. Aliquam in mollis eros. Integer interdum neque ac dapibus efficitur. Sed rutrum nisi at ante gravida, ac ornare orci sagittis.",
  "In sit amet cursus metus. Vestibulum vitae scelerisque augue, ac consectetur velit. Nam in congue velit. Nam sagittis dolor orci, et viverra nulla pretium sit amet.",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet efficitur augue. Morbi eu laoreet augue. Curabitur porta convallis fermentum.",
  "Nulla id tempus nibh. Pellentesque lectus ipsum, condimentum posuere orci quis, pellentesque pharetra diam. Nullam quis nisl sit amet leo fringilla rhoncus.",
  "Nunc sed interdum neque, nec tincidunt felis. Vestibulum et sem est. Nulla pharetra, nibh sagittis dapibus rhoncus, leo justo accumsan veli.",
  "id ullamcorper lorem nisi euismod lectus. Nam sed dictum eros. Nullam vitae lorem pellentesque, imperdiet augue id, sollicitudin est.",
  "Pellentesque eu porttitor velit. Curabitur at diam est. Duis fermentum varius tincidunt. Aenean sagittis, ligula quis maximus tempus",
  "turpis urna imperdiet neque, sit amet vehicula velit quam sit amet neque. Donec scelerisque ultrices nulla, sed consequat ipsum tempus sed.",
  "Praesent maximus lectus justo, nec fermentum tortor dapibus vel. Suspendisse quis ante pellentesque, tincidunt ex eu, auctor tortor. Maecenas",
  "tincidunt suscipit mattis. Suspendisse a varius risus. Nullam gravida vitae nulla venenatis gravida. Curabitur bibendum orci id ultrices dapibus.",
  "Donec vitae ante at odio sagittis dictum quis et nisl. Vivamus erat orci, suscipit semper mauris ut, posuere ultricies diam. Aenean odio libero,",
  "ultricies ut hendrerit vitae, tincidunt a turpis. Vivamus gravida non quam sed congue. Donec dapibus fringilla metus a dapibus.",
  "Praesent imperdiet sollicitudin purus. Aenean felis velit, posuere ac metus id, venenatis viverra est.",
  "In in lobortis libero. Nullam dictum tincidunt mauris, ut accumsan urna rhoncus et.",
  "Curabitur velit nisl, viverra eget auctor sed, aliquet ut elit. Phasellus varius nibh turpis, a pretium sem ornare sed. Quisque justo libero",
  "ultrices nec placerat nec, tempus sed ligula.",
  "Nulla euismod aliquam urna, at tincidunt ipsum bibendum et. Maecenas euismod elit diam, ac pharetra nibh convallis quis. Nullam vulputate est ac dignissim suscipit.",
  "Ut fermentum non quam ut semper. In eget ornare tortor. Quisque libero purus, congue sit amet imperdiet ut, euismod et turpis. Vivamus quis metus nec orci hendrerit ultrices.",
  "Fusce sit amet placerat massa. Pellentesque iaculis vitae sem et ullamcorper.",
  "Proin porta dictum libero, in hendrerit mauris rutrum eleifend. Aliquam tincidunt libero quis purus varius, vel eleifend arcu semper.",
  "Praesent aliquet erat id pellentesque finibus. Maecenas maximus purus a lacus dapibus malesuada. Donec molestie elit ligula, et faucibus diam tincidunt at.",
  "Cras quis convallis ex. Aliquam in mollis eros. Integer interdum neque ac dapibus efficitur. Sed rutrum nisi at ante gravida, ac ornare orci sagittis.",
  "In sit amet cursus metus. Vestibulum vitae scelerisque augue, ac consectetur velit. Nam in congue velit. Nam sagittis dolor orci, et viverra nulla pretium sit amet.",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet efficitur augue. Morbi eu laoreet augue. Curabitur porta convallis fermentum.",
  "Nulla id tempus nibh. Pellentesque lectus ipsum, condimentum posuere orci quis, pellentesque pharetra diam. Nullam quis nisl sit amet leo fringilla rhoncus.",
  "Nunc sed interdum neque, nec tincidunt felis. Vestibulum et sem est. Nulla pharetra, nibh sagittis dapibus rhoncus, leo justo accumsan veli.",
  "id ullamcorper lorem nisi euismod lectus. Nam sed dictum eros. Nullam vitae lorem pellentesque, imperdiet augue id, sollicitudin est.",
  "Pellentesque eu porttitor velit. Curabitur at diam est. Duis fermentum varius tincidunt. Aenean sagittis, ligula quis maximus tempus",
  "turpis urna imperdiet neque, sit amet vehicula velit quam sit amet neque. Donec scelerisque ultrices nulla, sed consequat ipsum tempus sed.",
  "Praesent maximus lectus justo, nec fermentum tortor dapibus vel. Suspendisse quis ante pellentesque, tincidunt ex eu, auctor tortor. Maecenas",
  "tincidunt suscipit mattis. Suspendisse a varius risus. Nullam gravida vitae nulla venenatis gravida. Curabitur bibendum orci id ultrices dapibus.",
  "Donec vitae ante at odio sagittis dictum quis et nisl. Vivamus erat orci, suscipit semper mauris ut, posuere ultricies diam. Aenean odio libero,",
  "ultricies ut hendrerit vitae, tincidunt a turpis. Vivamus gravida non quam sed congue. Donec dapibus fringilla metus a dapibus.",
  "Praesent imperdiet sollicitudin purus. Aenean felis velit, posuere ac metus id, venenatis viverra est.",
  "In in lobortis libero. Nullam dictum tincidunt mauris, ut accumsan urna rhoncus et.",
  "Curabitur velit nisl, viverra eget auctor sed, aliquet ut elit. Phasellus varius nibh turpis, a pretium sem ornare sed. Quisque justo libero",
  "ultrices nec placerat nec, tempus sed ligula.",
  "Nulla euismod aliquam urna, at tincidunt ipsum bibendum et. Maecenas euismod elit diam, ac pharetra nibh convallis quis. Nullam vulputate est ac dignissim suscipit.",
  "Ut fermentum non quam ut semper. In eget ornare tortor. Quisque libero purus, congue sit amet imperdiet ut, euismod et turpis. Vivamus quis metus nec orci hendrerit ultrices.",
  "Fusce sit amet placerat massa. Pellentesque iaculis vitae sem et ullamcorper.",
  "Proin porta dictum libero, in hendrerit mauris rutrum eleifend. Aliquam tincidunt libero quis purus varius, vel eleifend arcu semper.",
  "Praesent aliquet erat id pellentesque finibus. Maecenas maximus purus a lacus dapibus malesuada. Donec molestie elit ligula, et faucibus diam tincidunt at.",
  "Cras quis convallis ex. Aliquam in mollis eros. Integer interdum neque ac dapibus efficitur. Sed rutrum nisi at ante gravida, ac ornare orci sagittis.",
  "In sit amet cursus metus. Vestibulum vitae scelerisque augue, ac consectetur velit. Nam in congue velit. Nam sagittis dolor orci, et viverra nulla pretium sit amet.",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet efficitur augue. Morbi eu laoreet augue. Curabitur porta convallis fermentum.",
  "Nulla id tempus nibh. Pellentesque lectus ipsum, condimentum posuere orci quis, pellentesque pharetra diam. Nullam quis nisl sit amet leo fringilla rhoncus.",
  "Nunc sed interdum neque, nec tincidunt felis. Vestibulum et sem est. Nulla pharetra, nibh sagittis dapibus rhoncus, leo justo accumsan veli.",
  "id ullamcorper lorem nisi euismod lectus. Nam sed dictum eros. Nullam vitae lorem pellentesque, imperdiet augue id, sollicitudin est.",
  "Pellentesque eu porttitor velit. Curabitur at diam est. Duis fermentum varius tincidunt. Aenean sagittis, ligula quis maximus tempus",
  "turpis urna imperdiet neque, sit amet vehicula velit quam sit amet neque. Donec scelerisque ultrices nulla, sed consequat ipsum tempus sed.",
  "Praesent maximus lectus justo, nec fermentum tortor dapibus vel. Suspendisse quis ante pellentesque, tincidunt ex eu, auctor tortor. Maecenas",
  "tincidunt suscipit mattis. Suspendisse a varius risus. Nullam gravida vitae nulla venenatis gravida. Curabitur bibendum orci id ultrices dapibus.",
  "Donec vitae ante at odio sagittis dictum quis et nisl. Vivamus erat orci, suscipit semper mauris ut, posuere ultricies diam. Aenean odio libero,",
  "ultricies ut hendrerit vitae, tincidunt a turpis. Vivamus gravida non quam sed congue. Donec dapibus fringilla metus a dapibus.",
  "Praesent imperdiet sollicitudin purus. Aenean felis velit, posuere ac metus id, venenatis viverra est.",
  "In in lobortis libero. Nullam dictum tincidunt mauris, ut accumsan urna rhoncus et.",
  "Curabitur velit nisl, viverra eget auctor sed, aliquet ut elit. Phasellus varius nibh turpis, a pretium sem ornare sed. Quisque justo libero",
  "ultrices nec placerat nec, tempus sed ligula.",
  "Nulla euismod aliquam urna, at tincidunt ipsum bibendum et. Maecenas euismod elit diam, ac pharetra nibh convallis quis. Nullam vulputate est ac dignissim suscipit.",
  "Ut fermentum non quam ut semper. In eget ornare tortor. Quisque libero purus, congue sit amet imperdiet ut, euismod et turpis. Vivamus quis metus nec orci hendrerit ultrices.",
  "Fusce sit amet placerat massa. Pellentesque iaculis vitae sem et ullamcorper.",
  "Proin porta dictum libero, in hendrerit mauris rutrum eleifend. Aliquam tincidunt libero quis purus varius, vel eleifend arcu semper.",
  "Praesent aliquet erat id pellentesque finibus. Maecenas maximus purus a lacus dapibus malesuada. Donec molestie elit ligula, et faucibus diam tincidunt at.",
  "Cras quis convallis ex. Aliquam in mollis eros. Integer interdum neque ac dapibus efficitur. Sed rutrum nisi at ante gravida, ac ornare orci sagittis.",
  "In sit amet cursus metus. Vestibulum vitae scelerisque augue, ac consectetur velit. Nam in congue velit. Nam sagittis dolor orci, et viverra nulla pretium sit amet.",
];

export function TweetStorm() {
  const [tweets, setTweets] = useState([]);
  const [visibleTweetCount, setVisibleTweetCount] = useState(0);

  useEffect(() => {
    const randomTweets = [];
    seedTweets.map((tweet) => {
      randomTweets.push({
        text: tweet,
        top: Math.floor(Math.random() * 400),
        left: Math.floor(Math.random() * 400),
        z: Math.floor(Math.random() * 50),
      });
    });
    console.log(randomTweets);
    setTweets(randomTweets);
  }, []);

  return (
    <Box fill>
      <Box width={"medium"} pad={"small"} direction={"row-responsive"}>
        <Text>{`Tweets`}</Text>
        <RangeInput
          value={visibleTweetCount}
          onChange={(e) => setVisibleTweetCount(e.target.value)}
          min={0}
          max={tweets.length}
        />
      </Box>
      <Box fill>
        {tweets.slice(0, visibleTweetCount).map((tweet) => (
          <AbsoluteBox top={tweet.top} left={tweet.left}>
            <Box
              border
              pad={"small"}
              round
              width={"medium"}
              background={"#efefef"}
            >
              {tweet.text.slice(0, 100)}
            </Box>
          </AbsoluteBox>
        ))}
      </Box>
    </Box>
  );
}
