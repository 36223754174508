import { useEffect, useState } from "react";
import { useLoaderData, Outlet, Link } from "react-router-dom";
import { Text, Box, TextInput, TextArea, Button } from "grommet";
import { Like, Send } from "grommet-icons";
import { io } from "socket.io-client";
import config from "./config";

const socket = io(config.server_url);

export function Gaurav() {
  const [poll, setPoll] = useState({});
  const [pollText, setPollText] = useState("");

  function createNewPoll() {
    const components = pollText.split("\n");
    const text = components[0];
    const options = components[1].split(",");
    socket.emit("poll:createNew", { text, options });
  }

  useEffect(() => {
    socket.on("poll:new", (msg) => {
      console.log(msg);
      setPoll(msg);
    });
  }, []);
  return (
    <div>
      <Box pad={"small"} gap={"small"}>
        <TextArea
          placeholder="poll text"
          value={pollText}
          onChange={(event) => setPollText(event.target.value)}
        />
        <Box direction={"row-responsive"} gap={"small"}>
          <Button
            label={"Publish"}
            primary
            alignSelf="start"
            onClick={createNewPoll}
          ></Button>
          <Button
            label={"Reset"}
            alignSelf="start"
            onClick={() => {
              socket.emit("poll:reset", {});
            }}
          ></Button>
        </Box>
      </Box>
      <Box border round pad={"medium"} margin={"large"}>
        <Text weight={"bold"}>{poll.text}</Text>
        <Box height={"1em"}></Box>
        <Box direction={"row-responsive"} gap={"small"}>
          {poll.options ? (
            <Box>
              {poll.options.map((option, ix) => (
                <Box
                  key={ix}
                  direction="row-responsive"
                  gap={"xsmall"}
                  alignContent={"center"}
                >
                  <Text>{`${option} : ${
                    poll.counts[option] ? poll.counts[option] : 0
                  }`}</Text>
                </Box>
              ))}
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box pad={"small"} margin={"medium"} gap={"medium"}>
        {poll &&
          poll.comments &&
          poll.comments.map((comment, ix) => (
            <Box
              key={ix}
              direction={"column"}
              gap={"medium"}
              border
              round
              pad={"small"}
              width={"fit-content"}
            >
              <Text>{comment}</Text>
            </Box>
          ))}
      </Box>
    </div>
  );
}
