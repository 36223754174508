import {
  Box,
  Data,
  DataTable,
  DataSearch,
  Toolbar,
  Image,
  Text,
  Heading,
  Paragraph,
  Button,
  Anchor,
} from "grommet";
import { Outlet, Link } from "react-router-dom";
import users from "./data/users.json";

export function HomePage() {
  return (
    <Box>
      <Heading level={3}>Mining Hate </Heading>
      <Paragraph fill>
        Mining Hate is an improvised interactive performance built with audience
        generated content that seeks to create awareness of the practices used
        by anonymous hackers to target journalists and minorities in India to
        spread disinformation.
      </Paragraph>

      <Paragraph fill>
        To get the best experience of this performance, we request you to
        register for it using this form:
      </Paragraph>
      <Anchor
        href="https://docs.google.com/forms/d/e/1FAIpQLSfwiB0ChojIi_suA6PC2IjyxpC5TkrPgdIF1WFYrzTerOwoqQ/viewform"
        target="_blank"
        primary
      >
        Register
      </Anchor>
      {/* <Link to={"/manager"}>
        <Heading level={5} margin={"none"}>
          Manager
        </Heading>
      </Link>
      <Link to={"/show"}>
        <Heading level={5} margin={"none"} responsive="true">
          Show
        </Heading>
      </Link> */}
      <Box height="4em"></Box>
      <Heading level={3}>Participate</Heading>
      <Box gap="medium">
        <Text>
          After registration, click the button below to be able to participate
          in the interactive performance
        </Text>
        <Link to={"/participate"}>
          <Button primary label={"Participate"} />
        </Link>
      </Box>
      {/* <Box direction="row-responsive">
        <Box width={"fit-content"} pad={"small"} background={"light-2"}>
          <Data data={users}>
            <Toolbar>
              <DataSearch />
            </Toolbar>
            <DataTable
              columns={[
                {
                  property: "photo",
                  render: (datum) => (
                    <Box
                      width={"xxsmall"}
                      height={"xxsmall"}
                      overflow={"hidden"}
                    >
                      {datum.photo != "" ? (
                        <Link to={`/${datum.id}/userdata`}>
                          <Image fit="cover" src={`${datum.photo}`} />
                        </Link>
                      ) : (
                        <Box fill background="black"></Box>
                      )}
                    </Box>
                  ),
                },
                {
                  property: "email",
                  primary: true,
                  render: (datum) => (
                    <Box>
                      <Text>{datum.name}</Text>
                      <Text size={"xsmall"}>{datum.email}</Text>
                    </Box>
                  ),
                },
              ]}
            />
          </Data>
        </Box>
        <Box fill pad={"small"}>
          <Outlet />
        </Box>
      </Box> */}
    </Box>
  );
}
