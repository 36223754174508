import { css } from "styled-components";

const Theme = {
  global: {
    font: {
      family: "IBM Plex Mono",
    },
    colors: {
      background: "#ffffff",
      brand: "#c1121f",
      "accent-1": "#fdf0d5",
      "accent-2": "#7F7AB0",
      "accent-3": "#252653",
      "neutral-1": "#669bbc",
      "neutral-2": "#856993",
      "neutral-3": "#EDC9C4",
      "text-dark": "#edc9c4",
      "text-light": "#fcbfa4",
      text: {
        dark: "#edc9c4",
        light: "#514e80",
      },
    },
    focus: {
      border: {
        color: "accent-1",
      },
    },
  },
  carousel: {
    animation: {
      duration: 0,
    },
  },
  heading: {
    font: {
      family: "IBM Plex Mono",
    },
    responsive: true,
    responsiveBreakpoint: 400,
    color: {
      dark: "#edc9c4",
      light: "#514e80",
    },
    lineHeight: "2.2em",
  },
  paragraph: {
    font: {
      family: "IBM Plex Mono",
    },
  },
  button: {
    border: { radius: "0.2em" },
    default: {
      border: { radius: "xsmall" },
      color: "text",
      padding: {
        horizontal: "medium",
        vertical: "small",
      },
      font: {
        family: "IBM Plex Mono",
        extend: `letter-spacing: 0.2em`,
      },
    },
    primary: {
      background: { color: "#003049" },
      extend: `font-size: 1.6em;`,
      padding: {
        horizontal: "medium",
        vertical: "small",
      },
      color: "#fdf0d5",
    },
    secondary: {
      border: { color: "brand", width: "4px", radius: "small" },
      color: "text",
      padding: {
        horizontal: "8px",
        vertical: "4px",
      },
    },
  },
  text: {
    font: {
      family: "IBM Plex Mono",
    },
  },
};

export default Theme;
