import { Box, Text, Image, CheckBox, Button } from "grommet";
import { useState, useEffect } from "react";
import config from "../config";
const base64 = require("base-64");
var utf8 = require("utf8");

function getCacheName(text) {
  var bytes = utf8.encode(text);
  var encoded = base64.encode(bytes);
  return encoded;
}

/**
 *
 * This component is used to show all the pre computed images generated for a user
 * depending on the category of the precomputed images (based on generic prompt, profession etc )
 * the filename is prefixed by a key and index, hence its important to pass the key and size
 * (total number of files for that user)
 *
 */
export function ImageGrid({
  users,
  onSelect,
  interactive,
  fileKey,
  size,
  fileName,
  onClear,
}) {
  console.log({ users, interactive, fileKey, size, fileName });
  const [checks, setChecks] = useState([]);

  useEffect(() => {
    setChecks(Array.from({ length: users.length * size }, () => false));
  }, [users]);

  function clearSelection() {
    setChecks(Array.from({ length: users.length * size }, () => false));
    if (onClear) {
      onClear();
    }
  }

  return (
    <Box>
      {interactive ? (
        <Box background={"neutral-2"} pad={"small"}>
          <Button plain label={"Reset"} onClick={clearSelection} fill={false} />
        </Box>
      ) : null}
      {users && users.length != 0 ? (
        <Box gap="small">
          {users.map((user, ix1) => {
            return (
              <Box>
                <Text>{user.name + " - " + user.email}</Text>

                <Box direction="row-responsive" gap="xsmall">
                  {Array.from({ length: size }, (v, k) => k).map((ix, ix2) => {
                    return (
                      <Box width="xsmall" height="xsmall" border>
                        <Image
                          fit={"cover"}
                          src={
                            `${config.server_url}/` +
                            getCacheName(fileName + user.email) +
                            `${fileKey}_${ix}`
                          }
                        ></Image>
                        {interactive ? (
                          <CheckBox
                            checked={checks[ix1 * size + ix2]}
                            onChange={(e) => {
                              // console.log(chec);
                              let index = ix1 * size + ix2;
                              let newChecks = [
                                ...checks.slice(0, index),
                                e.target.checked,
                                ...checks.slice(index + 1),
                              ];
                              setChecks(newChecks);
                              // console.log(newChecks);
                              onSelect(ix1, ix2);
                            }}
                          />
                        ) : null}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : null}
    </Box>
  );
}
