import { createRoot } from "react-dom/client";
import { HomePage } from "./HomePage";
import { Box, Grommet, Text } from "grommet";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { User, loader as userLoader } from "./User";
import { UserData } from "./UserData";
import { Screensaver } from "./Screensaver";
import { Poll } from "./Poll";
import { Meme } from "./Meme";
import { Meme2 } from "./Meme2";
import { Gaurav } from "./Gaurav";
import { TweetStorm } from "./TweetStorm";
import { Manager } from "./Manager";
import { Show } from "./Show";
import { Participate } from "./Participate";
import Theme from "./components/theme";

// import { Screensaver } from "./Screensaver";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    // children: [
    //   {
    //     path: ":userId",
    //     element: <User />,
    //     loader: userLoader,
    //     children: [
    //       {
    //         path: "userdata",
    //         element: <UserData />,
    //       },
    //       {
    //         path: "screensaver",
    //         element: <Screensaver />,
    //       },
    //       {
    //         path: "poll",
    //         element: <Poll />,
    //       },
    //       {
    //         path: "meme",
    //         element: <Meme />,
    //       },
    //       {
    //         path: "meme2",
    //         element: <Meme2 />,
    //       },
    //     ],
    //   },
    //   {
    //     path: "/consent",
    //     element: <Text>consent</Text>,
    //   },
    // ],
  },
  {
    path: "gaurav",
    element: <Gaurav />,
  },
  {
    path: "tweetstorm",
    element: <TweetStorm />,
  },
  {
    path: "manager",
    element: <Manager />,
  },
  {
    path: "show",
    element: <Show />,
  },
  {
    path: "participate",
    element: <Participate />,
  },
]);

function Root({ children }) {
  return (
    <Grommet theme={Theme} full>
      <Box pad="medium">
        <RouterProvider router={router}>{children}</RouterProvider>
      </Box>
    </Grommet>
  );
}

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <Root>
    <HomePage />
  </Root>
);
